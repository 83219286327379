<template>
  <exception-wrapper>
    <template slot="img">
      <img
        src="@/assets/images/svg/ic-403.svg"
        alt="403"
        width="216px"
        height="250px"
      />
    </template>
    <template slot="title">403 </template>
    <template slot="text">抱歉，你无权访问该页面 </template>
  </exception-wrapper>
</template>

<script>
  import exceptionWrapper from './exceptionWrapper.vue';

  export default {
    name: 'exception403',
    components: { exceptionWrapper }
  };
</script>
